import React from "react";


const TinySlider = () => {
  let a = {
    width: "500px",
    height: "800px",
    backgroundColor: "blue"
  }
  return (
    <div>
      <div className="my-slider" style={a}>
        
      </div>
    </div>
  );
};

export default TinySlider;
