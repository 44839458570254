import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Header from './component1/Header';
import TinySlider from './tinySlider/tinySlider';
// import { Button, IconButton, DeleteIcon  } from "@material-ui/core/Button";

ReactDOM.render(
  
    <div>
      {/* <Header /> */}
      <TinySlider />
    </div>
  ,
  document.getElementById('root')
);

